<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Compras - Ver</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>

                 <b-col md="6">
                  <b-form-group>
                    <label>Proveedor: </label>
                    <v-select disabled placeholder="Seleccione un proveedor" class="w-100" :filterable="false" label="name" v-model="provider" :options="providers"></v-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Numero :">
                    <b-form-input disabled class="text-right" type="text" ref="number" v-model="purchase_order.number"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Emision:">
                    <b-form-input disabled type="date" ref="broadcast_date" v-model="purchase_order.broadcast_date"></b-form-input>
                  </b-form-group>
                </b-col>

                    <b-col md="2">
                   <b-form-group label=".">
                    <b-button disabled class="form-control btn" variant="primary"  ><i class="fas fa-cart-plus"></i> Productos (F2)</b-button>
                   </b-form-group>
                </b-col>



                 <b-col md="3">
                  <b-form-group label="Forma de Pago :">
                    <b-form-select disabled v-model="purchase_order.way_to_pay" :options="way_to_pay"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Moneda:">
                    <b-form-select disabled v-model="purchase_order.coin" :options="coins"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group>
                    <label for="">Tipo de Cambio :</label>
                    <b-form-input disabled class="text-right" type="number"  v-model="purchase_order.exchange_rate"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Almacen :">
                    <b-form-select disabled v-model="purchase_order.id_warehouse" :options="warehouse"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Valor Unitario:">
                    <b-form-select disabled v-model="purchase_order.unit_value" :options="unit_value"></b-form-select>
                  </b-form-group>
                </b-col>


                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-input disabled rows="1"  v-model="purchase_order.observation" max-rows="3"></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- Detalle Entrada -->
                 <div class="col-md-12">
                  <div class="table-responsive mt-3">
                        <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                          <thead >
                            <tr>
                              <th rowspan="2" width="3%" class="text-center">#</th>
                              <th rowspan="2" width="5%" class="text-center">Cod.</th>
                              <th rowspan="2" width="40%" class="text-center">Nombre</th>
                              <th rowspan="2" width="10%" class="text-center">UM</th>
                              <th rowspan="2" width="8%" class="text-center">Cantidad</th>
                              <th rowspan="2" width="9%" class="text-center">Imp. Unit.</th>
                              <th colspan="2" width="10%" class="text-center">Descuento</th>
                              <th rowspan="2" width="7%" class="text-center">V. Unit. <br> Neto</th>
                              <th rowspan="2" width="7%" class="text-center">P. Unit.</th>
                              <th rowspan="2" width="8%" class="text-center">P. Total</th>
                           
                            </tr>
                            <tr>
                              <th class="text-center">%</th>
                              <th class="text-center">Importe</th>
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in purchase_order_detail" :key="it">
                            <tr>
                                <td class="align-middle text-center">{{ it + 1 }}</td>
                                <td class="align-middle text-left">{{ item.code }}</td>
                                <td class="align-middle text-left">{{ item.name  }}</td>
                                <td class="align-middle text-center">{{ item.unit_measure }}</td>
                                <td class="align-middle text-center">{{ item.quantity }}</td>
                                <td class="align-middle text-center">{{ item.unit_value }}</td>
                                <td class="align-middle text-center">{{ item.percentage_discount }}</td>
                                <td class="align-middle text-right">{{ item.unit_discount }}</td>
                                <td class="align-middle text-right">{{ item.net_unit_value }}</td>
                                <td class="align-middle text-right">{{ item.unit_price }}</td>
                                <td class="align-middle text-center">{{ item.total_price }}</td>
                  
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                <small v-if="errors.shopping_detail" class="col-md-12 form-text text-center text-danger">Agregue productos</small>
                <!-- Detalle Entrada -->
                <b-col md="12" class="mt-3"></b-col>


                <b-col md="8">
                  <b-row>
                     <b-col md="6">
                       <div class="table-responsive mt-3">
                        <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                          <thead >
                            <tr>
                              <th  width="20%" class="text-center">Fecha</th>
                              <th  width="70%" class="text-center">Order de Pedido</th>
                            
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in purchase_order.linkages" :key="it">
                            <tr>
                              <td class="text-center">{{ item.broadcast_date }}</td>
                              <td class="text-center">{{ item.reference  }}</td>
    
                            </tr>
                          </tbody>
                        </table>
                       </div>
                     </b-col>
                   </b-row>
                </b-col>
                <b-col md="4">
                  <b-form-group  label-cols-sm="7" label="Ope. Gravadas :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" disabled v-model="purchase_order.taxed_operation"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="7" label="Ope. Exonerada :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" disabled v-model="purchase_order.exonerated_operation"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Ope. Inafecta :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" disabled v-model="purchase_order.unaffected_operation"></b-form-input>
                  </b-form-group>
                  <b-form-group  label-cols-sm="7" label="Descuentos :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" disabled v-model="purchase_order.discount"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="7" label="IGV (18%) :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" disabled v-model="purchase_order.igv"></b-form-input>
                  </b-form-group>
                  <b-form-group label-cols-sm="7" label="Total :" class="text-right m-0">
                    <b-form-input class="text-right" type="number" step="any" disabled v-model="purchase_order.total"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
       
                <b-col md="2">
                  <b-link class="btn form-control btn-primary" :to="{ path: '/orden-de-compra/listar' }" append>Regresar</b-link >
                </b-col>

              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- Modal Products -->
    <ModalProducts />
    <!-- Modal Clients -->
    <ModalProviders />
    <LoadingComponent :is-visible="isLoading"/>
    
  </div>
</template>

<script>
///import vue select
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
// components
import ModalProviders from '@/views/components/ModalProvider'
import ModalProducts from './components/ModalProduct'
import mShoppingDetail from './components/ShoppingDetail'
import LoadingComponent from './../pages/Loading'

export default {
  name: "ShoppingEdit",
  props: ["id_purchase_order"],
  components:{
      vSelect,
      ModalProducts,
      mShoppingDetail,
      ModalProviders,
      LoadingComponent,
  },
  data() {
    return {
      isLoading:false,
      module: 'PurchaseOrder',
      role: 5,

      purchase_order: {
        id_purchase_order_detail:'',
        id_provider:'',
        id_user:'',
        id_warehouse:'',
        linkages:'',
        operation_type:'02',
        invoice_type:'01',
        serie:'',
        number:'',
        broadcast_date:moment(new Date()).local().format("YYYY-MM-DD"),
        arrival_date:moment(new Date()).local().format("YYYY-MM-DD"),
        coin:'PEN',
        reference:'',
        exchange_rate: '1.00',
        way_to_pay: '01-008',
        payment_type:'01',
        payment_method:'008',
        payment_deadline:'',
        affection_for_detraction: '',
        unit_value: '0',
        observation:'',
        expenses:(0).toFixed(2),
        taxed_operation: (0).toFixed(2),
        exonerated_operation: (0).toFixed(2),
        unaffected_operation: (0).toFixed(2),
        discount: (0).toFixed(2),
        subtotal: (0).toFixed(2),
        igv: (0).toFixed(2),
        total: (0).toFixed(2),
        state:1,
        shopping_detail: [],

      },

      providers: [],
      provider:null,

      warehouse:[],
      purchase_order_detail:[],
   
      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
      ],

       way_to_pay:[
        {value:"01-008",text:'Contado - Efectivo'},
        {value:"01-009",text:'Contado - Efectivo en los demás casos'},
        {value:"01-001",text:'Contado - Depósito en cuenta'},
        {value:"01-002",text:'Contado - Giro'},
        {value:"01-003",text:'Contado - Transferencia de fondos'},
        {value:"01-004",text:'Contado - Orden de pago'},
        {value:"01-005",text:'Contado - Tarjeta de Débito'},
        {value:"03-7",text:'Crédito - 7 dias'},
        {value:"03-15",text:'Crédito - 15 dias'},
        {value:"03-30",text:'Crédito - 30 dias'},
        {value:"03-45",text:'Crédito - 45 dias'},
        {value:"03-60",text:'Crédito - 60 dias'},
        {value:"03-75",text:'Crédito - 75 dias'},
        {value:"03-90",text:'Crédito - 90 dias'},
      ],

 

      unit_value: [
        { value: '0',text:'Sin IGV'},
        { value: '1',text:'Con IGV'},
        
      ],
      //errors
      errors: {
        id_provider: false,
        serie:false,
        number:false,
        broadcast_date:false,
        arrival_date:false,
        shopping_detail:false,
        exchange_rate:false,
        id_warehouse:false,
        way_to_pay:false,
        affection_for_detraction:false,
      },
      validate: false,
    };
  },
  mounted() {

    this.mLoadResetShoppingDetail();
    this.ListWarehouse();
    this.ViewPurchaseOrder();
    
  },
  methods: {
    ListWarehouse,
    ViewPurchaseOrder,
    NameUnitMeasure,

    ...mapActions('Shopping',['mLoadUnitValue','mLoadExpensesValue']),
    ...mapActions('Shopping',['mLoadResetShoppingDetail']),
    ...mapActions('Shopping',['mLoadAddShoppingDetail']),
    ...mapActions('Shopping',['mLoadTotalsShoppingDetail']),
    
  },

  computed: {
    // ...mapState('Shopping',['shopping_detail','total_shopping']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let establishment = window.localStorage.getItem("id_establishment");
      establishment = JSON.parse(je.decrypt(establishment));
      return establishment;
    }
  },
};
function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function ListWarehouse() {
  let me = this;
  let url = this.url_base + "warehouse/list-active";
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role,},
  })
    .then(function (response) {
      me.warehouse.push({value: '', text: '-- Seleccione un almacen --'});
      if (response.data.status == 200) {
        for (let index = 0; index < response.data.result.length; index++) {
          const element = response.data.result[index];
          me.warehouse.push({value: element.id_warehouse, text: element.name});
        }
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
}

function ViewPurchaseOrder() {

  let id_purchase_order = je.decrypt(this.id_purchase_order);
  let me = this;
  let url = this.url_base + "purchase-order/view/" + id_purchase_order;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: this.role,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {

          me.purchase_order.way_to_pay = response.data.result.purchase_order.way_to_pay;
          me.purchase_order.id_purchase_order = response.data.result.purchase_order.id_purchase_order;
          me.purchase_order.id_provider = response.data.result.purchase_order.id_provider;
          me.purchase_order.id_warehouse = response.data.result.purchase_order.id_warehouse;
          me.purchase_order.id_user = response.data.result.purchase_order.id_user;
          me.purchase_order.linkages = response.data.result.purchase_order.linkages;
          me.purchase_order.operation_type = response.data.result.purchase_order.operation_type;
          me.purchase_order.type_invoice = response.data.result.purchase_order.type_invoice;
          me.purchase_order.serie = response.data.result.purchase_order.serie;
          me.purchase_order.number = response.data.result.purchase_order.number;
          me.purchase_order.broadcast_date = response.data.result.purchase_order.broadcast_date;
          me.purchase_order.arrival_date = response.data.result.purchase_order.arrival_date;
          me.purchase_order.coin = response.data.result.purchase_order.coin;
          me.purchase_order.reference = response.data.result.purchase_order.reference;
          me.purchase_order.exchange_rate = response.data.result.purchase_order.exchange_rate;
          me.purchase_order.affection_for_detraction = response.data.result.purchase_order.affection_for_detraction;
          me.purchase_order.unit_value = response.data.result.purchase_order.unit_value;
          me.purchase_order.payment_type = response.data.result.purchase_order.payment_type;
          me.purchase_order.payment_method = response.data.result.purchase_order.payment_method;
          me.purchase_order.payment_deadline = response.data.result.purchase_order.payment_deadline;
          me.purchase_order.observation = response.data.result.purchase_order.observation;
          me.purchase_order.expenses = response.data.result.purchase_order.expenses;
          me.purchase_order.taxed_operation = response.data.result.purchase_order.taxed_operation;
          me.purchase_order.exonerated_operation = response.data.result.purchase_order.exonerated_operation;
          me.purchase_order.unaffected_operation = response.data.result.purchase_order.unaffected_operation;
          me.purchase_order.discount = response.data.result.purchase_order.discount;
          me.purchase_order.subtotal = response.data.result.purchase_order.subtotal;
          me.purchase_order.igv = response.data.result.purchase_order.igv;
          me.purchase_order.total = response.data.result.purchase_order.total;
          me.purchase_order.state = response.data.result.purchase_order.state;
          me.provider = {id: response.data.result.purchase_order.id_provider, name: response.data.result.purchase_order.name+" - "+ response.data.result.purchase_order.document_number};
    

  
          me.purchase_order_detail = response.data.result.purchase_order_detail; 
          
      } else {
        Swal.fire("Sistema", "A Ocurrido un error", "error");
      }

      me.isLoading = false;
    })
}



</script>
